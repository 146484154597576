<template>
  <div class="content-box-padding">
    <p class="line-text">
      南京浦蓝大气环境与绿色发展研究院是为助力南京大学“一流大气科学学科”发展战略，服务南京市“两落地、一融合”决策部署，建设以重大科技研发为基础、以成果产业落地为导向的南京市市级备案新型研发机构，由南京大学与南京市浦口区政府共建，运营实体为南京浦蓝大气环境研究院有限公司。
    </p>
    <img
      src="~assets/image/about_us/研究院简介1.png"
      alt="研究院简介1"
      class="img-center"
      style="margin: 44px 0"
    />
    <div class="line-text">
      浦蓝研究院依托南京大学大气与地球系统科学（教育部）国际合作联合实验室，以大气污染防治和绿色发展方向专业科研人才，服务国家和地方大气复合污染防治、应对气候变化和绿色低碳发展工作，同时也承担面向生态环境保护工作的产业研发、专业技术人才培养以及国内外合作交流等任务。以“两山”理论作指导，浦蓝研究院围绕生态环境以及绿色发展主题设立了孵化引进平台，积极打造以高技术公司为特征的产业集群，吸引了一批国内外青年科学家到平台上进行创业工作。当前，浦蓝研究院主要业务方向为：大气立体监测及集成分析，新型监测设备平台研发，专业气象服务及气象产业推广，低碳发展及大气复合污染防治策略及实施。<br />
      目前，研究院已获得3A资质认证，顺利通过了ISO9001国际质量保证体系认证、ISO14001环境管理体系认证、ISO45001职业健康安全管理体系认证，作为环保协会和气象协会理事单位，公司累计获得知识产权20余项。
    </div>
    <img
      src="~assets/image/about_us/研究院简介2.png"
      alt="研究院简介2"
      class="img-center"
      style="margin: 44px 0"
    />
  </div>
</template>
